import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	StyledQuickLinks as BaseQuickLinks,
	IFixedGridProps,
	StyledLinkText,
	Link,
	Responsive,
} from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';

interface ICustomQuickLinksProps extends IFixedGridProps {
	children: ReactNode[];
	style?: React.CSSProperties;
}

export const CustomQuickLinksSection = styled(BaseQuickLinks)`
	${StyledLinkText} {
		color: ${Color.PrimaryDarkGray100};
		flex-grow: 0;
		margin-right: 13px;

		:hover {
			color: ${Color.PrimaryOrange100};
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-right: 4px;
		}
	}

	${Link} {
		padding-top: 20px;
		padding-bottom: 20px !important;
		justify-content: flex-start;
	}
`;

export const QuickLinks = ({ children, ...rest }: ICustomQuickLinksProps) => (
	<CustomQuickLinksSection {...rest}>{children}</CustomQuickLinksSection>
);
