import {
	ContactCard,
	StyledCardContent,
	StyledCardPicture,
	IContactCardProps,
	Responsive,
	StyledCardTitle,
	StyledLinkText,
	StyledCardLink,
	StyledCardChildren,
	StyledIconLink,
} from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { Color } from '../../../styles/Color';

export const ContactItem = styled(ContactCard)<IContactCardProps>`
	${StyledCardTitle} {
		color: ${Color.PrimaryOrange100};
		font-size: 24px;
		font-weight: normal;
		margin-bottom: 4px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			font-size: 18px;
			margin-bottom: 0px;
		}
	}

	${StyledCardLink} {
		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			margin-top: 8px;
		}
	}

	${StyledIconLink} {
		svg {
			transform: translateY(2px);
		}
	}

	${StyledLinkText} {
		color: ${Color.PrimaryDarkGray100};
	}

	${StyledCardChildren} {
		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			margin-bottom: 0;
			margin-top: 0;
		}
	}

	${StyledCardPicture} {
		border-radius: 8px;
		transition: box-shadow 0.2s;
		border: 1px solid ${Color.PrimaryLightGray};

		img {
			border-radius: 8px;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			max-width: 160px;
			border-radius: 8px;

			img {
				border-radius: 8px;
			}
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			max-width: 80px;
		}
	}

	&:hover {
		${StyledCardPicture} {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		${StyledCardContent} {
			margin-left: 16px;
			margin-right: 0;
			width: 20%;

			div:first-of-type {
				width: 80%;
			}
		}

		${StyledCardPicture} {
			max-width: 80px;
		}
	}
`;
