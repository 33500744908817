import {
	ChevronRightIcon,
	Link,
	LinkText,
	QuickLinkItem,
} from '@m2/blueprint-ui';
import React from 'react';

export interface IQuickLinkInternalProps {
	title: string;
	url: string;
}

export const QuickLinkInternal = ({ title, url }: IQuickLinkInternalProps) => (
	<QuickLinkItem>
		<Link href={url}>
			<LinkText>{title}</LinkText>
			<ChevronRightIcon variation="highlight" />
		</Link>
	</QuickLinkItem>
);
