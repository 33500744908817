import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	Card as BaseCard,
	CardOrientation,
	IResponsiveImageSource,
	isRelativeUrl,
	IStyledCardProps,
	Responsive,
	StyledCardContent,
	StyledCardLink,
	StyledCardPicture,
	StyledCardTitle,
	StyledIconLink,
	StyledLinkText,
	withCardContainerContext,
} from '@m2/blueprint-ui';

import { Color } from '../../../styles/Color';
import { useNavigate } from '../../../navigation';

interface ICardProps {
	children?: ReactNode;
	title: string;
	linkTitle?: string;
	linkHref: string;
	imageSource: IResponsiveImageSource;
	imageAlt?: string;
	className?: string;
}

export const getOrientationStyle = (orientation: CardOrientation) => {
	if (orientation === 'horizontal') {
		return `
			${StyledCardContent} {
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 16px;

				${StyledCardLink} {
					display: flex;
					line-height: normal;

					@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
						display: flex;
					}
				}
			}
		`;
	}

	return `

	`;
};

export const CustomStyledCardComponent = styled(BaseCard)<IStyledCardProps>`
	${StyledCardPicture} {
		transition: box-shadow 0.2s;
		border-radius: 8px;
		img {
			border-radius: 8px;
		}
	}

	${StyledIconLink} {
		svg {
			transform: translateY(2px);
		}
	}

	${StyledCardContent} {
		margin-top: 16px;
		justify-content: flex-start;

		p {
			margin-bottom: 16px;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			line-height: 1.33;

			p {
				margin-bottom: 0;
				font-size: 16px;
			}
		}
	}

	${Responsive.getStyles<CardOrientation, IStyledCardProps>(
		'cardOrientation',
		getOrientationStyle,
	)};

	${StyledCardTitle} {
		color: ${Color.PrimaryOrange100};
		font-size: 24px;
		font-weight: normal;
		margin-bottom: 4px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			margin-bottom: 7px;
			font-size: 18px;
		}
	}

	${StyledLinkText} {
		color: ${Color.PrimaryDarkGray100};
		width: 90%;

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			width: fit-content;
		}
	}

	&:hover {
		${StyledCardPicture} {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		}

		${StyledLinkText} {
			color: ${Color.PrimaryOrange100};
		}
	}
`;

export const Card = withCardContainerContext<ICardProps>((cardProps) => {
	const handleClick = useOnClickCardHandler(cardProps.linkHref);

	return <CustomStyledCardComponent {...cardProps} onClick={handleClick} />;
});

export function useOnClickCardHandler(url: string) {
	const navigate = useNavigate();
	return (e: React.MouseEvent<HTMLElement>) => {
		// Disable the native link click to prevent triggering navigation twice

		if ((e.target as HTMLElement).tagName.toLocaleLowerCase() === 'a') {
			e.preventDefault();
		}
		const isRelative = isRelativeUrl(url);

		if (isRelative) {
			navigate(url);
		} else {
			window.open(url);
		}
	};
}
