import React from 'react';
import {
	CardsContainer as BaseCardsContainer,
	ICardsContainerProps,
} from '@m2/blueprint-ui';

export const CardsContainer = (props: ICardsContainerProps) => (
	<BaseCardsContainer {...props} fullyClickable />
);

CardsContainer.defaultProps = {};
